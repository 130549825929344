import {hitGoogleTagManager} from "./init_google_tag_manager";

interface GAEvent {
    event: "GAEvent";
    GAEventCategory: "lista nieruchomosci";
    GAEventAction: "klikniecie";
    GAEventLabel: IElementType;
    GAEventNoInteraction: true;
}
type IElementType = "rzut" | "nieruchomosc" | "zapytaj o cene" | "rezerwacja" | "zapytaj dewelopera" | "promocja";

export const gtmEventPropertyListClick = (elementType: IElementType) => {
    const gtmData: GAEvent = {
        event: "GAEvent",
        GAEventCategory: "lista nieruchomosci",
        GAEventAction: "klikniecie",
        GAEventLabel: elementType,
        GAEventNoInteraction: true
    };
    hitGoogleTagManager(gtmData);
};
