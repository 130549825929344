import React from "react";

import {Button} from "@pg-design/button";
import {mt, p, prettyScroll, w100} from "@pg-design/helpers-css";
import {Modal} from "@pg-design/modal";

interface IProps {
    children: React.ReactNode;
    modalState: boolean;
    onModalClose: (cancelChanges: boolean) => void;
}

export const MapPlacesModal: React.FC<IProps> = (props) => {
    const onModalCloseWithChangesCancel = () => props.onModalClose(true);

    return (
        <Modal isOpen={props.modalState} onModalClose={onModalCloseWithChangesCancel}>
            <Modal.Header variant="bar_mini">Miejsca na mapie</Modal.Header>
            <Modal.Content css={[prettyScroll(), p(0, 4)]}>
                {props.children}

                <div>
                    <Button css={[w100, mt()]} variant="filled_primary" onClick={() => props.onModalClose(false)}>
                        Zapisz
                    </Button>
                    <Button css={[w100, mt()]} variant="none_secondary" onClick={onModalCloseWithChangesCancel}>
                        Anuluj
                    </Button>
                </div>
            </Modal.Content>
        </Modal>
    );
};
