import {IPublicTransportType} from "../types/IPublicTransportType";
import {TransportPoiType} from "../utils/PoiType";

/**
 *  TODO: For some reason we use both, train and railway keys, it's probably not because of the api but rather due to implementation.
 *      There should be a common approach (if it's because of api, remove this comment
 */

export const activeTransportMarkerUrls = {
    [IPublicTransportType.SUBWAY]: require("../images/pins/transport/subway_active.svg"),
    [IPublicTransportType.TRAIN]: require("../images/pins/transport/train_active.svg"),
    [TransportPoiType.RAILWAY]: require("../images/pins/transport/train_active.svg"),
    [IPublicTransportType.BUS]: require("../images/pins/transport/bus_active.svg"),
    [IPublicTransportType.TRAM]: require("../images/pins/transport/tram_active.svg")
};

export const passiveTransportMarkerUrls = {
    [IPublicTransportType.SUBWAY]: require("../images/pins/transport/subway_passive.svg"),
    [IPublicTransportType.TRAIN]: require("../images/pins/transport/train_passive.svg"),
    [TransportPoiType.RAILWAY]: require("../images/pins/transport/train_passive.svg"),
    [IPublicTransportType.BUS]: require("../images/pins/transport/bus_passive.svg"),
    [IPublicTransportType.TRAM]: require("../images/pins/transport/tram_passive.svg")
};
