import React, {useMemo, useState} from "react";
import {css, useTheme} from "@emotion/react";

import {backgroundColor, borderRadius, calculateRemSize, display, flex, listUnStyled, m, mt, ph, pointer, pr, underline} from "@pg-design/helpers-css";
import {PoiBusIcon, PoiSubwayIcon, PoiTrainIcon, PoiTramIcon} from "@pg-design/icons";
import {Text} from "@pg-design/text";

import {useUniqPublicTransportRouteNames} from "../hooks/use_uniq_public_transport_route_names";
import {IPublicTransportRoute} from "../types/IPublicTransportRoute";

interface IProps {
    busRoutes?: IPublicTransportRoute[] | null;
    railwayRoutes?: IPublicTransportRoute[] | null;
    subwayRoutes?: IPublicTransportRoute[] | null;
    tramRoutes?: IPublicTransportRoute[] | null;
    activePoiRoute?: IPublicTransportRoute | null;
    onTransportRouteClick: (publicTransportRoute: IPublicTransportRoute) => void;
}

const transportTypeColors = {
    bus: "#9BD7FF",
    railway: "#BCAFE1",
    subway: "#FFCDA5",
    tram: "#B9E19B"
};

const ADDITIONAL_ROUTES_FROM_INDEX = 25;

const getTitleForRoute = (route: IPublicTransportRoute) => {
    return `Od ${route.from} do ${route.to}`;
};

const isRouteActive = (route?: IPublicTransportRoute, activePoiRoute?: IPublicTransportRoute | null) => {
    return !!route && !!activePoiRoute && route?.id === activePoiRoute?.id;
};

export const RegionTransportRoutes = (props: IProps) => {
    const theme = useTheme();
    const [showAdditionalRoutes, setShowAdditionalRoutes] = useState(false);

    const routeBackground = (transportType: keyof typeof transportTypeColors, route?: IPublicTransportRoute) => {
        return backgroundColor(isRouteActive(route, props.activePoiRoute) ? theme.colors.primary : transportTypeColors[transportType]);
    };

    const busRoutesRaw = useUniqPublicTransportRouteNames(props.busRoutes);
    const busRoutes = useMemo(() => {
        return busRoutesRaw?.sort((firstRoute, secondRoute) => {
            const isFirstRouteNightRoute = firstRoute.name[0] === "N";
            const isSecondRouteNightRoute = secondRoute.name[0] === "N";

            if ((isFirstRouteNightRoute && isSecondRouteNightRoute) || (!isFirstRouteNightRoute && !isSecondRouteNightRoute)) {
                return 0;
            }

            return isFirstRouteNightRoute ? 1 : -1;
        });
    }, [busRoutesRaw]);
    const subwayRoutes = useUniqPublicTransportRouteNames(props.subwayRoutes);
    const tramRoutes = useUniqPublicTransportRouteNames(props.tramRoutes);
    const railwayRoutes = useUniqPublicTransportRouteNames(props.railwayRoutes);

    const subwayRoutesFrom = subwayRoutes?.length || 0;
    const railwayRoutesLength = railwayRoutes?.length || 0;
    const tramRoutesLength = tramRoutes?.length || 0;
    const busRoutesLength = busRoutes?.length || 0;
    const totalRoutesLength = subwayRoutesFrom + railwayRoutesLength + tramRoutesLength + busRoutesLength;

    const routeIndexNormalization = {
        subway: 0,
        railway: subwayRoutesFrom,
        tram: subwayRoutesFrom + railwayRoutesLength,
        bus: subwayRoutesFrom + railwayRoutesLength + tramRoutesLength
    };

    const routeDisplayStyle = (type: keyof typeof routeIndexNormalization, index: number) => {
        return showAdditionalRoutes || routeIndexNormalization[type] + index < ADDITIONAL_ROUTES_FROM_INDEX ? "" : display("none");
    };

    const toggleShowAdditionalRoutes = () => {
        setShowAdditionalRoutes((prevState) => !prevState);
    };

    return (
        <div>
            <ul css={listWrapper}>
                {subwayRoutes.map((route, index) => (
                    <li
                        key={route.id}
                        css={[routeStyle, routeBackground("subway", route), routeDisplayStyle("subway", index)]}
                        title={getTitleForRoute(route)}
                        onClick={() => props.onTransportRouteClick(route)}
                    >
                        <PoiSubwayIcon size="2.4" wrapperSize="2" wrapperColor="transparent" css={[routeIconStyle]} />
                        <Text as="span" variant="info_txt_2" strong>
                            {route.name}
                        </Text>
                    </li>
                ))}

                {railwayRoutes.map((route, index) => (
                    <li
                        key={route.id}
                        css={[routeStyle, routeBackground("railway", route), routeDisplayStyle("railway", index)]}
                        title={getTitleForRoute(route)}
                        onClick={() => props.onTransportRouteClick(route)}
                    >
                        <PoiTrainIcon size="1.6" wrapperSize="2" wrapperColor="transparent" css={[routeIconStyle]} />
                        <Text as="span" variant="info_txt_2" strong>
                            {route.name}
                        </Text>
                    </li>
                ))}

                {tramRoutes.map((route, index) => (
                    <li
                        key={route.id}
                        css={[routeStyle, routeBackground("tram", route), routeDisplayStyle("tram", index)]}
                        title={getTitleForRoute(route)}
                        onClick={() => props.onTransportRouteClick(route)}
                    >
                        <PoiTramIcon size="1.6" wrapperSize="2" wrapperColor="transparent" css={[routeIconStyle]} />
                        <Text as="span" variant="info_txt_2" strong>
                            {route.name}
                        </Text>
                    </li>
                ))}

                {busRoutes.map((route, index) => (
                    <li
                        key={route.id}
                        css={[routeStyle, routeBackground("bus", route), routeDisplayStyle("bus", index)]}
                        title={getTitleForRoute(route)}
                        onClick={() => props.onTransportRouteClick(route)}
                    >
                        <PoiBusIcon size="1.6" wrapperSize="2" wrapperColor="transparent" css={[routeIconStyle]} />
                        <Text as="span" variant="info_txt_2" strong>
                            {route.name}
                        </Text>
                    </li>
                ))}
            </ul>

            {totalRoutesLength > ADDITIONAL_ROUTES_FROM_INDEX ? (
                <Text as="div" variant="info_txt_1" css={[mt(1), underline, pointer]} onClick={toggleShowAdditionalRoutes}>
                    {showAdditionalRoutes ? "Pokaż mniej linii komunikacyjnych" : "Pokaż więcej linii komunikacyjnych"}
                </Text>
            ) : null}
        </div>
    );
};

const listWrapper = css`
    ${listUnStyled};
    ${flex()};
    ${m(0.5, 0, 0, 0)};
    flex-wrap: wrap;
    gap: ${calculateRemSize(0.5)};
`;

const routeStyle = css`
    ${flex("center", "space-between")};
    ${borderRadius()};
    ${ph(1)};
    height: 2.4rem;
    cursor: pointer;
`;

const routeIconStyle = css`
    ${pr(1)};
`;
