import {useMemo} from "react";
import {useSelector} from "react-redux";

import {IRPStore} from "../../app/rp_reducer";
import {IPoi} from "../types/IPoi";
import {IPublicTransportRoute} from "../types/IPublicTransportRoute";
import {TransportPoiType} from "../utils/PoiType";
import {useOsmPoisRoutesGrouped} from "./use_osm_pois_routes_grouped";

interface IHook {
    hasTransportPois: boolean;
    hasRoutes: boolean;
    busPoi: IPoi | null;
    railwayPoi: IPoi | null;
    subwayPoi: IPoi | null;
    tramPoi: IPoi | null;
    transportPois: Record<TransportPoiType, IPoi[]> | null;
    routes: {
        busRoutes: IPublicTransportRoute[] | null;
        railwayRoutes: IPublicTransportRoute[] | null;
        subwayRoutes: IPublicTransportRoute[] | null;
        tramRoutes: IPublicTransportRoute[] | null;
    };
}

export const useTransportPois = (): IHook => {
    const transportPois = useSelector((state: IRPStore) => state.maps.poi.pois.transport);

    const osmPoisRoutesGrouped = useOsmPoisRoutesGrouped();
    return useMemo(() => {
        if (transportPois && !Array.isArray(transportPois)) {
            const busPoi = transportPois.bus?.[0] || null;
            const railwayPoi = transportPois.railway?.[0] || null;
            const subwayPoi = transportPois.subway?.[0] || null;
            const tramPoi = transportPois.tram?.[0] || null;

            const busRoutes = osmPoisRoutesGrouped?.bus || null;
            const railwayRoutes = osmPoisRoutesGrouped?.train || null;
            const subwayRoutes = osmPoisRoutesGrouped?.subway || null;
            const tramRoutes = osmPoisRoutesGrouped?.tram || null;

            const hasTransportPois = Boolean(busPoi || railwayPoi || subwayPoi || tramPoi);
            const hasRoutes = Boolean(busRoutes?.length || railwayRoutes?.length || subwayRoutes?.length || tramRoutes?.length);

            return {
                hasTransportPois,
                hasRoutes,

                busPoi,
                subwayPoi,
                railwayPoi,
                tramPoi,
                routes: {
                    busRoutes,
                    railwayRoutes,
                    subwayRoutes,
                    tramRoutes
                },
                transportPois
            };
        }

        return {
            hasTransportPois: false,
            hasRoutes: false,
            busPoi: null,
            subwayPoi: null,
            railwayPoi: null,
            tramPoi: null,
            transportPois: null,
            routes: {
                busRoutes: null,
                railwayRoutes: null,
                subwayRoutes: null,
                tramRoutes: null
            }
        };
    }, [transportPois, osmPoisRoutesGrouped]);
};
