import {useMemo} from "react";
import {useTheme} from "@emotion/react";

import {countDistance} from "@pg-mono/geo-utils";
import {convertToLatLngLiteralOfPoland} from "@pg-mono/open-street-map";

import {PoiRouteInfoWindow} from "../components/PoiRouteInfoWindow";
import {activeStopsRadius} from "../constants/transport_type_radius";
import {IPublicTransportRoute} from "../types/IPublicTransportRoute";
import {IPublicTransportStop} from "../types/IPublicTransportStop";
import {getMapPassiveTransportStopMarker} from "../utils/get_map_passive_transport_stop_marker";
import {getMapTransportPolyline} from "../utils/get_map_transport_polyline";
import {usePublicTransportRouteStops} from "./use_public_transport_route_stops";

const getNearestStop = (targetCoordinates: [number, number] | undefined, stops: IPublicTransportStop[]): IPublicTransportStop | null => {
    if (!targetCoordinates) {
        return null;
    }

    let nearestStop: IPublicTransportStop | null = null;
    let nearestDistance = Number.MAX_SAFE_INTEGER;

    stops.forEach((stop) => {
        const distance = countDistance(convertToLatLngLiteralOfPoland(stop.coordinates), convertToLatLngLiteralOfPoland(targetCoordinates));

        if (distance < nearestDistance) {
            nearestDistance = distance;
            nearestStop = stop;
        }
    });

    return nearestStop;
};

export const useOsmPoiRouteMapElements = (targetCoordinates: [number, number], poiRoute: IPublicTransportRoute | null) => {
    const theme = useTheme();
    const {poiRouteWithStops, poiRouteStops} = usePublicTransportRouteStops({
        poiId: poiRoute?.id || 0,
        skipFetching: !poiRoute?.id
    });

    return useMemo(() => {
        const stops = poiRouteStops.filter((stop) => Number.isFinite(stop.coordinates[0]) && Number.isFinite(stop.coordinates[1]));
        const nearestStop = getNearestStop(targetCoordinates, stops);

        return {
            polyline: poiRouteWithStops ? getMapTransportPolyline({...poiRouteWithStops}, theme) : null,
            markers: poiRouteWithStops
                ? stops
                      .filter((stop) => isStopPassive(targetCoordinates, stop.coordinates))
                      .map((stop) =>
                          getMapPassiveTransportStopMarker(stop, poiRouteWithStops.type, nearestStop, () => <PoiRouteInfoWindow poiRoute={poiRouteWithStops} />)
                      )
                : [],
            stops,
            nearestStop
        };
    }, [poiRoute?.id, poiRouteWithStops?.id]);
};

function isStopPassive(centerCoordinates: [number, number], stopCoordinates: [number, number]) {
    /**
     * Passive stops are stops that shouldn't have a standard interaction, like browsing/interacting with possible routes in popup
     */
    const distanceFromCenter = getDistanceFromCenter(centerCoordinates, stopCoordinates);

    return distanceFromCenter > activeStopsRadius;
}

function getDistanceFromCenter(centerCoordinates: [number, number], stopCoordinates: [number, number]) {
    const centerCoords = convertToLatLngLiteralOfPoland(centerCoordinates);
    const stopCoords = convertToLatLngLiteralOfPoland(stopCoordinates);
    return countDistance(centerCoords, stopCoords);
}
