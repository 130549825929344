import React from "react";
import {css, Theme} from "@emotion/react";

import {CloseIcon} from "@pg-design/icons";

import {IPublicTransportRoute} from "../types/IPublicTransportRoute";

interface IProps {
    onClose?: () => void;
    poiRoute: IPublicTransportRoute;
}

export const PoiRouteInfoWindow = (props: IProps) => {
    return (
        <div css={offerInfoWindow}>
            {props.onClose && (
                <span css={closePosition} onClick={props.onClose}>
                    <CloseIcon size="1" />
                </span>
            )}

            <div css={[heading]}>{props.poiRoute.name}</div>
            <div>Od: {props.poiRoute.from}</div>
            <div>Do: {props.poiRoute.to}</div>
        </div>
    );
};

const offerInfoWindow = css`
    background-color: #fff;
    border-radius: 0.4rem;
    padding: 2rem;
`;

const closePosition = (theme: Theme) => css`
    position: absolute;
    top: 1rem;
    right: 1rem;
    cursor: pointer;

    @media (max-width: ${theme.breakpoints.md}) {
        right: 1.5rem;
    }
`;

const heading = css`
    font-size: 2.2rem;
    font-weight: 500;
`;
