import {PropsWithChildren} from "react";
import {css, Theme} from "@emotion/react";

import {borderRadius, flexAbsoluteCenter, mh} from "@pg-design/helpers-css";
import {CarOutlineIcon, PoiBusIcon, WalkIcon} from "@pg-design/icons";
import {Text} from "@pg-design/text";

import {TravelMode} from "../../property/utils/TravelMode";

type IProps = PropsWithChildren<{
    selected: boolean;
    onClick: () => void;
    travelMode: TravelMode | null;
    duration: string | null;
    className?: string;
}>;

export const TravelModeButton = (props: IProps) => {
    const {selected, travelMode, onClick, duration, className} = props;
    const iconProps = {size: "1.6", fill: "#fff"};

    return (
        <span css={travelModeButtonStyle(selected)} onClick={onClick} className={className}>
            {travelMode === TravelMode.DRIVING && <CarOutlineIcon {...iconProps} />}
            {travelMode === TravelMode.WALKING && <WalkIcon {...iconProps} />}
            {travelMode === TravelMode.TRANSIT && <PoiBusIcon {...iconProps} />}{" "}
            {selected && duration && (
                <Text as="span" strong variant="info_txt_1" css={durationTextStyle}>
                    {duration}
                </Text>
            )}
        </span>
    );
};

//  Styles
const travelModeButtonStyle = (selected: boolean) => (theme: Theme) => css`
    width: 100%;
    height: 2.4rem;
    background-color: ${selected ? theme.colors.primary : theme.colors.gray[700]};
    cursor: pointer;
    ${flexAbsoluteCenter};
    ${borderRadius()};
    ${mh()};

    &:last-of-type,
    &:first-of-type {
        margin: 0;
    }

    & > svg {
        fill: ${selected ? theme.colors.secondary : "#fff"};
    }
`;

const durationTextStyle = css`
    display: inline-block;
    margin-left: 1rem;
    white-space: pre;
`;
