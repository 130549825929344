import {useEffect, useMemo} from "react";
import {useDispatch, useSelector} from "react-redux";

import {uniq} from "@pg-mono/nodash";

import {IRPStore} from "../../app/rp_reducer";
import {fetchOsmPoiRoutes} from "../actions/fetch_osm_poi_routes";
import {IPublicTransportRoute} from "../types/IPublicTransportRoute";
import {IPublicTransportType} from "../types/IPublicTransportType";
import {PoiType} from "../utils/PoiType";

export const useOsmPoiRoutes = (poiType: PoiType, poiId: number) => {
    const requestedArea = useSelector((state: IRPStore) => state.maps.poi.requestedArea);
    const poiRoutes = useSelector((state: IRPStore) => state.maps.poi.poisRoutes?.[poiId]) || null;
    const dispatch = useDispatch();

    const poiRoutesGrouped = useMemo(() => {
        if (!poiRoutes) {
            return null;
        }

        return poiRoutes.reduce(
            (acc, route) => {
                acc[route.type] = uniq([...(acc[route.type] || []), route]);

                return acc;
            },
            {} as Record<IPublicTransportType, IPublicTransportRoute[]>
        );
    }, [poiRoutes]);

    useEffect(() => {
        if (!requestedArea?.latitude || !requestedArea?.longitude || poiType !== PoiType.TRANSPORT) {
            return;
        }

        dispatch(fetchOsmPoiRoutes({poiId}));
    }, [requestedArea, poiId]);

    return {
        poiRoutes,
        hasAnyPoiRoutes: Boolean(poiRoutes && poiRoutes.length > 0),
        poiRoutesGrouped
    };
};
