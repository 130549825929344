// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React from "react";
import {useDispatch} from "react-redux";

import type {IMarker} from "@pg-mono/open-street-map";

import {setActivePoi} from "../actions/set_poi_travel_directions";
import {activeTransportMarkerUrls} from "../constants/transport_marker_urls";
import {createGetOsmPoiMarker} from "../utils/create_get_osm_poi_marker";
import {PoiType, TransportPoiType} from "../utils/PoiType";
import {usePois} from "./use_pois";
import {useTransportPois} from "./use_transport_pois";

const education_pin = require("../images/pins/education_pin.svg");
const sport_pin = require("../images/pins/sport_pin.svg");
const health_pin = require("../images/pins/health_pin.svg");
const entertainment_pin = require("../images/pins/entertainment_pin.svg");
const shops_pin = require("../images/pins/shops_pin.svg");
const food_pin = require("../images/pins/food_pin.svg");

type IHook = Record<string, IMarker[]>;

export interface IUsePoisMarkersOptions {
    disableInitiallyOpenedPoiId?: boolean;
}

export const usePoisMarkers = (offer?: {geo_point: {coordinates: [number, number]}}, options?: IUsePoisMarkersOptions): IHook => {
    const dispatch = useDispatch();

    const {educationPois, entertainmentPois, foodPois, healthPois, sportPois, shopPois} = usePois();
    const {transportPois, subwayPoi, railwayPoi, busPoi, tramPoi} = useTransportPois();

    const getPoiMarker = createGetOsmPoiMarker((poi, poiType) => dispatch(setActivePoi(poi, poiType)), offer?.geo_point.coordinates);

    const transportPoiKeys = Object.keys(transportPois || {}) as TransportPoiType[];
    // INFO: This is related to the business requirement to initially open marker InfoWindow for "best" transport POI
    const initiallyOpenedTransportPoiId = options?.disableInitiallyOpenedPoiId ? undefined : subwayPoi?.id || railwayPoi?.id || busPoi?.id || tramPoi?.id;

    const transportPoisMarkers = !!transportPois
        ? transportPoiKeys.reduce((acc, key) => {
              return acc.concat(
                  ...transportPois[key].map((poi) => {
                      if (poi.id === initiallyOpenedTransportPoiId) {
                          return getPoiMarker(poi, PoiType.TRANSPORT, activeTransportMarkerUrls[key], {initiallyOpened: true}, key);
                      }
                      return getPoiMarker(poi, PoiType.TRANSPORT, activeTransportMarkerUrls[key], {}, key);
                  })
              );
          }, [] as IMarker[])
        : [];
    const educationPoisMarkers = educationPois.map((poi) => getPoiMarker(poi, PoiType.EDUCATION, education_pin));
    const entertainmentPoisMarkers = entertainmentPois.map((poi) => getPoiMarker(poi, PoiType.ENTERTAINMENT, entertainment_pin));
    const foodPoisMarkers = foodPois.map((poi) => getPoiMarker(poi, PoiType.FOOD, food_pin));
    const healthPoisMarkers = healthPois.map((poi) => getPoiMarker(poi, PoiType.HEALTH, health_pin));
    const sportPoisMarkers = sportPois.map((poi) => getPoiMarker(poi, PoiType.SPORT, sport_pin));
    const shopPoisMarkers = shopPois.map((poi) => getPoiMarker(poi, PoiType.SHOPS, shops_pin));

    return {
        [PoiType.EDUCATION]: educationPoisMarkers,
        [PoiType.ENTERTAINMENT]: entertainmentPoisMarkers,
        [PoiType.FOOD]: foodPoisMarkers,
        [PoiType.HEALTH]: healthPoisMarkers,
        [PoiType.SHOPS]: shopPoisMarkers,
        [PoiType.SPORT]: sportPoisMarkers,
        [PoiType.TRANSPORT]: transportPoisMarkers
    };
};
