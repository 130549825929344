import {delayHit, hitAlgolytics} from "@pg-mono/algolytics";

import {ViewType} from "../../view_type/ViewType";
import {getTrackedSiteData} from "./tracked_utils/tracked_site";

export enum MapInteractionHits {
    INITIALIZED = "map_initialized",
    DRAGGED = "map_dragged_interaction",
    ZOOMED = "map_zoom_interaction"
}
// map interaction
export const mapInteractionAlgolytics = (name: MapInteractionHits) => delayHit((payload: Object) => hitAlgolytics(name, payload), 500);

export const mapInteractionHit = (viewType: ViewType | null, name: MapInteractionHits) => {
    const payload = {
        viewType,
        ...getTrackedSiteData()
    };
    mapInteractionAlgolytics(name)(payload);
};

const mapClickActions = delayHit((payload) => hitAlgolytics("offer_list_map", payload), 500);

export const openSidebarHit = (viewType: string, offerId: number) => {
    const payload = {
        event_type: "open_sidebar",
        view_type: viewType,
        offer_id: offerId,
        ...getTrackedSiteData()
    };

    mapClickActions(payload);
};

export const sidebarCloseHit = (viewType: string) => {
    const payload = {
        event_type: "close_sidebar",
        view_type: viewType,
        ...getTrackedSiteData()
    };

    mapClickActions(payload);
};

export const sidebarInvestmentClickHit = (viewType: string, offerId: number) => {
    const payload = {
        event_type: "sidebar_offer_click",
        offer_id: offerId,
        view_type: viewType,
        ...getTrackedSiteData()
    };

    mapClickActions(payload);
};
