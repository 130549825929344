import {Theme} from "@emotion/react";

import {createPolyline, IPolyline, IPolylinePresetName} from "@pg-mono/open-street-map";

import {IPublicTransportRouteWithStops} from "../types/IPublicTransportRouteWithStops";
import {IPublicTransportType} from "../types/IPublicTransportType";

export function getMapTransportPolyline(transportRoute: IPublicTransportRouteWithStops, theme: Theme) {
    const polyline: IPolyline = createPolyline(getPoiRoutePolylinePreset(transportRoute.type), {
        id: `public-transport-route_${transportRoute.id}`,
        positions: transportRoute.geometry?.coordinates || [],
        skipInFitBounds: true
    })(theme);

    return polyline;
}

function getPoiRoutePolylinePreset(type?: IPublicTransportRouteWithStops["type"]): IPolylinePresetName {
    switch (type) {
        case IPublicTransportType.BUS:
            return "busLine";
        case IPublicTransportType.SUBWAY:
            return "subwayLine";
        case IPublicTransportType.TRAIN:
            return "trainLine";
        case IPublicTransportType.TRAM:
            return "tramLine";
        default:
            return "secondaryDashed";
    }
}
