import {delayHit, hitAlgolytics} from "@pg-mono/algolytics";

import {TravelMode} from "../../property/utils/TravelMode";
import {getTrackedSiteData} from "../../tracking/algolytics_hits/tracked_utils/tracked_site";
import {hitGoogleTagManager} from "../../tracking/google_tag_manager/init_google_tag_manager";
import {ViewType} from "../../view_type/ViewType";
import {IPoi} from "../types/IPoi";
import {IUserPoi} from "../types/IUserPoi";
import {PoiType} from "../utils/PoiType";

// GTM
enum GTMEvent {
    MAP = "map"
}

export enum PoiGTMModalAction {
    CREATE_MY_POI_OPEN = "create_my_poi_open",
    CREATE_MY_POI_SUBMIT = "create_my_poi_submit",
    DELETE_MY_POI = "delete_my_poi",
    IMPORTANT_PLACES_OFF = "important_places_off",
    IMPORTANT_PLACES_ON = "important_places_on",
    MAP_POI_CLICK = "map_poi_click",
    MAP_POI_CALCULATE = "map_poi_calculate",
    MY_POI_CALCULATE = "my_poi_calculate"
}

const mapEvent = (data: {action: PoiGTMModalAction; label?: string}) => {
    const eventData = {event: GTMEvent.MAP, label: "", ...data};

    hitGoogleTagManager(eventData);
};

// Algolytics
enum PoiMapAlgolyticsCollection {
    MAP_EVENTS_MEANS_OF_TRANSPORT = "map_events_means_of_transport",
    MAP_EVENTS_MY_POI = "map_events_my_poi",
    MAP_EVENTS_POI_CLICK = "map_events_poi_click",
    MAP_EVENTS_SHOW_POI = "map_events_show_poi"
}

enum PoiMapAlgolyticsEvent {
    ADD_MY_POI = "add_my_poi",
    POI_CLICK = "poi_click",
    MEANS_OF_TRANSPORT = "means_of_transport",
    SHOW_POI = "show_poi"
}

const poiMapAlgolyticsHit = (collection: PoiMapAlgolyticsCollection) =>
    delayHit((payload) => hitAlgolytics(collection, {...payload, ...getTrackedSiteData()}), 500);

const showPoi = (viewType: ViewType | null, checked: boolean, poiType: PoiType, offerId?: number | string, propertyId?: number | string) => {
    const payload = {
        category_id: poiType,
        checked,
        event_type: PoiMapAlgolyticsEvent.SHOW_POI,
        offer_id: offerId ? Number(offerId) : null,
        property_id: propertyId ? Number(propertyId) : null,
        view_type: viewType
    };

    poiMapAlgolyticsHit(PoiMapAlgolyticsCollection.MAP_EVENTS_SHOW_POI)(payload);
};

const poiClick = (viewType: ViewType | null, poiType: PoiType, poi: IPoi | IUserPoi, offerId?: number | string, propertyId?: number | string) => {
    const payload = {
        category_id: poiType,
        event_type: PoiMapAlgolyticsEvent.POI_CLICK,
        geo_point: [poi.lat, poi.lng],
        offer_id: offerId ? Number(offerId) : null,
        poi_id: poi.id,
        poi_name: poi.name,
        property_id: propertyId ? Number(propertyId) : null,
        view_type: viewType
    };

    poiMapAlgolyticsHit(PoiMapAlgolyticsCollection.MAP_EVENTS_POI_CLICK)(payload);
};

const meansOfTransportClick = (
    viewType: ViewType | null,
    poiType: PoiType,
    poi: IPoi | IUserPoi,
    travelMode: TravelMode,
    duration: number,
    offerId?: number | string,
    propertyId?: number | string
) => {
    const payload = {
        category_id: poiType,
        time: duration,
        event_type: PoiMapAlgolyticsEvent.MEANS_OF_TRANSPORT,
        means_of_transport: travelMode,
        offer_id: offerId ? Number(offerId) : null,
        poi_id: poi.id,
        poi_name: poi.name,
        property_id: propertyId ? Number(propertyId) : null,
        view_type: viewType
    };

    poiMapAlgolyticsHit(PoiMapAlgolyticsCollection.MAP_EVENTS_MEANS_OF_TRANSPORT)(payload);
};

const addMyPoi = (viewType: ViewType | null, poi: IUserPoi, offerId?: number | string, propertyId?: number | string) => {
    const payload = {
        address: poi.tags.address,
        event_type: PoiMapAlgolyticsEvent.ADD_MY_POI,
        geo_point: [poi.lat, poi.lng],
        offer_id: offerId ? Number(offerId) : null,
        poi_name: poi.name,
        property_id: propertyId ? Number(propertyId) : null,
        view_type: viewType
    };

    poiMapAlgolyticsHit(PoiMapAlgolyticsCollection.MAP_EVENTS_MY_POI)(payload);
};

export const poiAnalytics = {
    algolytics: {
        addMyPoi,
        poiClick,
        meansOfTransportClick,
        showPoi
    },
    gtm: {
        mapEvent
    }
};
