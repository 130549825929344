import {useMemo} from "react";
import {useSelector} from "react-redux";

import {compact} from "@pg-mono/nodash";

import {IRPStore} from "../../app/rp_reducer";
import {IPoi} from "../types/IPoi";
import {getPoiPluralizeLabel, PoiType} from "../utils/PoiType";

interface IHook {
    educationPois: IPoi[];
    entertainmentPois: IPoi[];
    foodPois: IPoi[];
    healthPois: IPoi[];
    shopPois: IPoi[];
    sportPois: IPoi[];
    pois: Record<PoiType, IPoi[]>;
    poisCountLabels: string[];
}

// Those PoiTypes are parsed separately
const excludedPoiTypes = [PoiType.TRANSPORT];

export const usePois = (distanceFromPoint = 1000): IHook => {
    const {pois} = useSelector((state: IRPStore) => state.maps.poi);

    const poiKeys = (Object.keys(pois) as PoiType[]).filter((key) => !excludedPoiTypes.includes(key));

    const poisWithExcludedPois = useMemo(() => {
        return poiKeys.reduce(
            (acc, key) => {
                return {...acc, [key]: pois[key]};
            },
            {} as Record<PoiType, IPoi[]>
        );
    }, [pois]);

    const poisInDistanceRange = useMemo(() => {
        return poiKeys.reduce(
            (acc, key) => {
                return {
                    ...acc,
                    [key]: poisWithExcludedPois[key].filter(({distance}) => distance <= distanceFromPoint)
                };
            },
            {} as Record<PoiType, IPoi[]>
        );
    }, [poisWithExcludedPois, distanceFromPoint]);

    const poisCountLabels = useMemo(
        () =>
            compact(
                poiKeys.reduce((acc, key) => {
                    const poiTypeCount = poisInDistanceRange[key].filter(({distance}) => distance <= distanceFromPoint).length;
                    const poiCountLabel = poiTypeCount ? getPoiPluralizeLabel(key, poiTypeCount) : [];
                    return acc.concat(poiCountLabel);
                }, [] as string[])
            ),
        [poisInDistanceRange]
    );

    return useMemo(() => {
        return {
            educationPois: poisInDistanceRange.education || [],
            entertainmentPois: poisInDistanceRange.entertainment || [],
            foodPois: poisInDistanceRange.food || [],
            healthPois: poisInDistanceRange.health || [],
            shopPois: poisInDistanceRange.shops || [],
            sportPois: poisInDistanceRange.sport || [],
            pois: poisWithExcludedPois,
            poisCountLabels
        };
    }, [pois]);
};
