import type {IMarker, LatLngLiteral} from "@pg-mono/open-street-map";

import {useGooglePoiTravelDirections} from "./use_google_poi_travel_directions";
import {IUsePoisMarkersOptions, usePoisMarkers} from "./use_osm_pois_markers";
import {useUserPoi} from "./use_osm_user_poi";

interface IHook {
    userPoiMarkers: IMarker[];
    poiDirectionsPolylineCoord: LatLngLiteral[];
    poisMarkers: Record<string, IMarker[]>;
}

type IOptions = IUsePoisMarkersOptions;

export const useOSMPoisMapObjects = (offer?: {geo_point: {coordinates: [number, number]}}, options?: IOptions): IHook => {
    const {poiDirectionsPolylineCoord} = useGooglePoiTravelDirections();
    const poisMarkers = usePoisMarkers(offer, options);
    const {userPoiMarkers} = useUserPoi(offer);

    return {
        userPoiMarkers,
        poiDirectionsPolylineCoord,
        poisMarkers
    };
};
