import React from "react";
import {css} from "@emotion/react";
import styled from "@emotion/styled";

export type ISwitcherProps = Omit<React.InputHTMLAttributes<HTMLInputElement>, "type" | "onChange"> & {
    checked: boolean;
    onChange: (value: boolean) => void;
    id: string;
    variant?: "small" | "big";
    labelContent?: JSX.Element | string;
} & ILabelScenarios;

//  Label scenarios - provide aria-labelledby if you're not providing a labelContent
type ILabelScenarios = INoLabelScenario | ILabelProvidedScenario;

type INoLabelScenario = {
    labelContent?: never;
    "aria-labelledby": string;
};

type ILabelProvidedScenario = {
    labelContent: JSX.Element | string;
    "aria-labelledby"?: never;
};

export const Switcher = (props: ISwitcherProps) => {
    const {onChange, checked, variant, id, labelContent, ...inputProps} = props;

    const onKeyDown = (event: React.KeyboardEvent) => {
        if (event.key === " ") {
            onChange(checked);
        }
    };

    return (
        <div css={componentContainerStyle}>
            {labelContent && (
                <label css={labelStyle} htmlFor={id}>
                    {labelContent}
                </label>
            )}
            <div css={switcherStyle}>
                <HiddenInput
                    {...inputProps}
                    type="checkbox"
                    checked={checked}
                    onChange={({target}) => {
                        onChange(target.checked);
                    }}
                    onKeyDown={onKeyDown}
                    id={id}
                />
                <div css={switchWrapStyle}>
                    <Switch checked={checked} variant={variant}>
                        <SwitchIndicator checked={checked} variant={variant} />
                    </Switch>
                </div>
            </div>
        </div>
    );
};

//  Styles
const componentContainerStyle = css`
    display: inline-flex;
    align-items: center;
`;

const labelStyle = css`
    cursor: pointer;
    margin: 0 3.2rem 0 0;
`;

const switcherStyle = css`
    position: relative;
    cursor: pointer;
`;

const switchWrapStyle = css`
    border-radius: 1.6rem;
    padding: 0.3rem;
`;

const HiddenInput = styled.input`
    cursor: inherit;
    position: absolute;
    opacity: 0;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    margin: 0;
    padding: 0;
    z-index: 1;

    &:focus-visible ~ div {
        outline: ${({theme}) => `0.2rem solid ${theme.colors.info}`};
    }
`;

const Switch = styled.div<Pick<ISwitcherProps, "variant" | "checked">>`
    cursor: pointer;
    width: 3.6rem;
    height: 1.8rem;
    padding: 0.2rem;
    transition: ${({theme}) => `background, ${theme.transition.timingFunction}, ${theme.transition.duration}`};

    ${({variant}) =>
        variant === "big" &&
        css`
            width: 4.8rem;
            height: 2.4rem;
            padding: 0.26rem;
        `}
    border-radius: 1.6rem;
    background: ${({checked, theme}) => (checked ? theme.colors.info : theme.colors.gray["500"])};
`;

const SwitchIndicator = styled.div<Pick<ISwitcherProps, "variant" | "checked">>`
    border-radius: 50%;
    width: 1.4rem;
    height: 1.4rem;
    transition: ${({theme}) => `transform, ${theme.transition.timingFunction}, ${theme.transition.duration}`};

    ${({checked}) => css`
        transform: ${checked && "translateX(1.8rem)"};
    `}

    ${({variant, checked}) =>
        variant === "big" &&
        css`
            width: 1.867rem;
            height: 1.867rem;
            transform: ${checked && "translateX(2.41rem)"};
        `}

    background: #fff;
`;
